import Script from 'next/script';
import { useContext } from 'react';
import { MobileAppContext } from '../context/mobile-app-provider';

export function ThirdPartyAnalytics(): JSX.Element {
  const { isMobileApp } = useContext(MobileAppContext);

  return (
    <>
      <Script id="segment-analytics" strategy="afterInteractive">
        {`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";}}();
        `}
      </Script>
      {!isMobileApp && (
        <>
          <Script
            strategy="afterInteractive"
            src="https://www.googletagmanager.com/gtag/js?id=UA-213751635-1"
          />
          <Script
            strategy="afterInteractive"
            src="https://www.googletagmanager.com/gtag/js?id=G-L2TNPDKLVN"
          />
          <Script id="gtag-conversation" strategy="afterInteractive">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-213751635-1', {
            'linker': {
              'domains': ['summerhealth.com', 'www.summerhealth.com', 'care.summerhealth.com', 'summer-health.us.auth0.com']
            }
          });
          gtag('config', 'AW-10814929781', {
            'linker': {
              'domains': ['summerhealth.com', 'www.summerhealth.com', 'care.summerhealth.com', 'summer-health.us.auth0.com']
            }
          });

          gtag('config', 'G-L2TNPDKLVN');
          `}
          </Script>
          <Script id="facebook-pixel" strategy="afterInteractive">
            {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '834825897831165');
          fbq('track', 'PageView');
        `}
          </Script>
          <Script id="quora-pixel" strategy="afterInteractive">
            {`
          !function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', 'https://a.quora.com/qevents.js');
          qp('init', '18efd4014c6c42fa9b062461c4630db8');
          qp('track', 'ViewContent');
        `}
          </Script>
          <Script id="taboola-analytics" strategy="afterInteractive">
            {`
          !function (t, f, a, x) {
            if (!document.getElementById(x)) {
               t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
            }
          }(document.createElement('script'),
          document.getElementsByTagName('script')[0],
          '//cdn.taboola.com/libtrc/unip/1530312/tfa.js',
          'tb_tfa_script');
        `}
          </Script>
          <Script
            id="dwin1"
            strategy="afterInteractive"
            src="https://www.dwin1.com/68112.js"
          />
        </>
      )}
    </>
  );
}
